import * as React from 'react'

import { 
    cardHeading,
    bodyCopy,
    link,
    cardWrap,
    cardContentWrap,
    roleHeading,
    bodyCopySmall,
    roleContentWrap,
    linkWrap
    } from './layout.module.css'

const Card = ({projectName, projectDescription, caseStudyLink, projectLink, projectLinkText, roleDescription}) => { 
    return (
        <div className={cardWrap}>
            <div className={cardContentWrap}>
                <h3 className={cardHeading}>{projectName}</h3>
                <p className={bodyCopy}>{projectDescription}</p>

                <div className={linkWrap}>
                    <a className={link} href={caseStudyLink} target="_blank" rel="noreferrer">View case study</a>
                    <a className={link} href={projectLink} target="_blank" rel="noreferrer">{projectLinkText}</a>
                </div>
            </div>
            <div className={roleContentWrap}>
                <h4 className={roleHeading}>My Role</h4>
                <p className={bodyCopySmall}>{roleDescription}</p>
            </div>
        </div>
    )
}
export default Card