import * as React from 'react'
import Layout from '../components/layout'
import Cardgrid from '../components/cardgrid'



const IndexPage = () => {
  return (
    <Layout 
      pageTitle="About Melissa Kaufmann"
      pageHeading="Melissa Kaufmann"
      pageSubheading="Interactive Designer & Developer"
     
      >
        <Cardgrid></Cardgrid>
    </Layout>
  )
}

export default IndexPage