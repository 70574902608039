import * as React from 'react'
import Card from '../components/card'


import { 
    cardGrid,
    sectionHeading
    } from './layout.module.css'

const Cardgrid = () => { 
    return (
        <div className={cardGrid}>
            <h2 className={sectionHeading}>Select Work</h2>
            <div>
                <Card
                    projectName="New York Circle Line App"
                    projectDescription="We created an app that provides language support for non-English speakers on Circle Line Tours, broadening access to tour narration."
                    caseStudyLink="https://www.expandtheroom.com/case-studies/circle-line-tours/" 
                    projectLinkText=""
                    roleDescription="Visual design, interaction design, prototyping, feature definition and refinement." 
                    > 
                </Card>
                <Card
                    projectName="NYRR Run Center"
                    projectDescription="We created an interactive kiosk that would act as a digital concierge for visitors to the NYRR Run Center to enrich the visitor experience."
                    caseStudyLink="https://www.expandtheroom.com/case-studies/nyrr-runcenter/"
                    projectLinkText=""
                    roleDescription="Visual design, interaction design, prototyping to ensure a fully accessible experience." 
                    > 
                </Card>
                <Card
                    projectName="Invest PR"
                    projectDescription="We created a website to help increase economic development in Puerto Rico, by attracting more businesses."
                    caseStudyLink="https://www.expandtheroom.com/case-studies/invest-puerto-rico/"
                    projectLink="https://www.investpr.org"
                    projectLinkText="View Website"
                    roleDescription="Frontend development of components using tailwind CSS, Alpine JS and PHP." 
                    > 
                </Card>
            </div>
        </div>
    )
}
export default Cardgrid