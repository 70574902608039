import * as React from 'react'

import { 
    container, 
    hero,
    heroHeading,
    subheading,
    blurb,
    link,
    textLink
    } from './layout.module.css'


const Layout = ({ pageTitle, pageHeading, pageSubheading, textCont, children }) => {
  return (
    <div>
        <title>{pageTitle}</title>

        <main className={container}>
          <div className={hero}>
            <h1 className={heroHeading}>{pageHeading}</h1>
            <h2 className={subheading}>{pageSubheading}</h2>
            <p className={blurb}>I work for <a className={textLink} href='https://www.expandtheroom.com'>ETR, a part of Infinum</a>, a digital agency specializing in user experience and strategy. I help guide our clients through the process of creating engaging, effective and accessible digital experiences.</p>
            <p className={blurb}>While my primary focus is design, I have a broad skill set that spans both design and development. At times, this allows me to take a more hands-on approach to shaping the end product, by designing in the browser, contributing front-end code or executing design QA. My blended background helps me to better understand complex problems and work within constraints to find simple and elegant solutions.</p>
          </div>
        
          
            {children}
          
            
        </main>
        <footer>
          <div className={container}>
            <a className={link} href="https://www.linkedin.com/in/melissa-kaufmann-61824b66/">LinkedIn</a>
            <a className={link} href="https://dribbble.com/melissa-kaufmann">Dribbble</a>
          </div>
        </footer>
    </div>
  )
}
export default Layout